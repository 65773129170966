/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/styles/global.css';
import '@fontsource/open-sans';
import '@fontsource/source-sans-pro';

import { AnimatePresence } from 'framer-motion';

export const wrapPageElement = ({ element }) => (
    <AnimatePresence exitBeforeEnter> { element } </AnimatePresence>
)